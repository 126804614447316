import * as THREE from 'three'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import {
	Environment,
	Scroll,
	ScrollControls,
	useScroll,
} from '@react-three/drei'
import { Model } from './Midi'
import useRefs from 'react-use-refs'
import { useState } from 'react'

const rsqw = (t, delta = 0.1, a = 1, f = 1 / (2 * Math.PI)) =>
	(a / Math.atan(1 / delta)) * Math.atan(Math.sin(2 * Math.PI * t * f) / delta)

const easeInOutSine = (x) => {
	return -(Math.cos(Math.PI * x) - 1) / 2
}
const easeOutSine = (x) => {
	return Math.sin((x * Math.PI) / 2)
}

const Scene = () => {
	const scroll = useScroll()
	const { width, height } = useThree((state) => state.viewport)
	const [orbit, group, midi, keyLight, fog] = useRefs()

	// let knobs = [0, 0, 0, 0, 0, 0, 0, 0]
	// let sliders = [0, 0, 0, 0, 0, 0, 0, 0]

	const [knobs, setKnobs] = useState([0, 0, 0, 0, 0, 0, 0, 0])
	const [sliders, setSliders] = useState([0, 0, 0, 0, 0, 0, 0, 0])

	useFrame((state, delta) => {
		const p1 = scroll.range(0, 0.125)
		const r1 = scroll.range(0.05, 0.075)
		const r2 = scroll.range(0.05, 0.5)
		const r3 = scroll.range(0.2, 0.3)
		const l1 = scroll.range(0.5, 0.5)

		//knobs
		const k1 = scroll.range(0.46, 0.1)
		const k2 = scroll.range(0.49, 0.1)
		const k3 = scroll.range(0.52, 0.1)
		const k4 = scroll.range(0.55, 0.1)
		const k5 = scroll.range(0.58, 0.1)
		const k6 = scroll.range(0.61, 0.1)
		const k7 = scroll.range(0.64, 0.1)
		const k8 = scroll.range(0.67, 0.1)

		//sliders
		const s1 = scroll.curve(0.7, 0.1)
		const s2 = scroll.curve(0.73, 0.1)
		const s3 = scroll.curve(0.75, 0.1)
		const s4 = scroll.curve(0.78, 0.1)
		const s5 = scroll.curve(0.81, 0.1)
		const s6 = scroll.curve(0.84, 0.1)
		const s7 = scroll.curve(0.87, 0.1)
		const s8 = scroll.curve(0.9, 0.1)

		const { x, y } = state.pointer
		orbit.current.rotation.y = (x / 8) * p1
		orbit.current.rotation.x = (-y / 8) * p1

		group.current.position.y =
			-0.75 + easeOutSine(p1) * 0.75 - easeInOutSine(r3) * 0.2
		group.current.rotation.x = -Math.PI / 2 + (Math.PI / 2) * p1
		group.current.rotation.y = easeInOutSine(r2) * Math.PI * 4

		// group.current.position.y = -0 + (1 - (1 - p1) * (1 - p1)) * 1.5

		fog.current.near = 4 + p1 * 16
		midi.current.rotation.x =
			Math.PI / 2 - (Math.PI / 2) * easeInOutSine(r3) * 0.6
		midi.current.rotation.y =
			0.7 * (Math.PI / 2) * easeInOutSine(r1) -
			0.7 * (Math.PI / 2) * easeInOutSine(r3) * 0.3

		setKnobs((prev) => {
			prev[0] = k1
			prev[1] = k2
			prev[2] = k3
			prev[3] = k4
			prev[4] = k5
			prev[5] = k6
			prev[6] = k7
			prev[7] = k8
			return [...prev]
		})

		setSliders((prev) => {
			prev[0] = s1
			prev[1] = s2
			prev[2] = s3
			prev[3] = s4
			prev[4] = s5
			prev[5] = s6
			prev[6] = s7
			prev[7] = s8
			return [...prev]
		})
		keyLight.current.position.set(
			2 + -15 * (1 - l1),
			4 + 11 * (1 - l1),
			3 + 2 * (1 - l1)
		)
	})
	return (
		<>
			<fog
				ref={fog}
				attach='fog'
				args={['white', 4, 20]}
			/>
			<directionalLight
				ref={keyLight}
				castShadow
				intensity={2}
			>
				<orthographicCamera
					attachObject={['shadow', 'camera']}
					args={[-10, 10, 10, -10, 0.5, 30]}
				/>
			</directionalLight>
			<group
				ref={orbit}
				rotation={[0, 0, 0]}
			>
				<group
					ref={group}
					rotation={[0, 0, 0]}
				>
					<group
						ref={midi}
						rotation={[Math.PI / 2, 0.65 * (Math.PI / 2), 0]}
					>
						<Model
							castShadow
							receiveShadow
							position={[0, 0, 0]}
							scale={[0.01, 0.01, 0.01]}
							knobs={knobs}
							sliders={sliders}
						/>
					</group>
				</group>
			</group>
			{/* <OrbitControls
				minPolarAngle={0}
				maxPolarAngle={Math.PI / 1.9}
				makeDefault
			/> */}
			<Environment
				background={false} // can be true, false or "only" (which only sets the background) (default: false)
				backgroundBlurriness={0.8} // optional blur factor between 0 and 1 (default: 0, only works with three 0.146 and up)
				backgroundIntensity={1} // optional intensity factor (default: 1, only works with three 0.163 and up)
				backgroundRotation={[0, Math.PI / 2, 0]} // optional rotation (default: 0, only works with three 0.163 and up)
				environmentIntensity={1} // optional intensity factor (default: 1, only works with three 0.163 and up)
				environmentRotation={[0, Math.PI / 2, 0]} // optional rotation (default: 0, only works with three 0.163 and up)
				// files={['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png']}
				path='/'
				// preset={null}
				preset='sunset'
				// scene={undefined} // adds the ability to pass a custom THREE.Scene, can also be a ref
				// encoding={undefined} // adds the ability to pass a custom THREE.TextureEncoding (default: THREE.sRGBEncoding for an array of files and THREE.LinearEncoding for a single texture)
			/>
		</>
	)
}
export default function App() {
	return (
		<Canvas
			shadows
			gl={{ antialias: false }}
			dpr={[1, 1.5]}
			camera={{ position: [0, 0, 16], fov: 10 }}
		>
			<color
				attach='background'
				args={['white']}
			/>
			<ScrollControls pages={20}>
				<Scene />
				<Scroll html>
					<div
						style={{
							height: '100vh',
							width: '100vw',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							fontSize: '1em',
							// fontFamily: '',
						}}
					>
						<img
							src='fromm_logo.png'
							alt='Fromm'
							style={{
								width: '50%',
								maxWidth: '200px',
							}}
						/>
						<p>mini midi controller</p>
					</div>
				</Scroll>
			</ScrollControls>
			{/* <fog
				attach='fog'
				args={['black', 1, 8]}
			/> */}
			{/* <Gltf
				castShadow
				receiveShadow
				src='midi.glb'
				position={[0, 0, 0]}
				scale={[0.01, 0.01, 0.01]}
			/> */}
		</Canvas>
	)
}
