/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.18 midi.glb 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Model(props) {
	const { nodes, materials } = useGLTF('/midi.glb')

	const k = props.knobs
	const s = props.sliders

	const scaleSlider = (value) => {
		return 42.5 - value * 58
	}

	const scaleKnob = (value) => {
		return -2.5 + value * 5
	}

	return (
		<group
			{...props}
			dispose={null}
		>
			<mesh
				geometry={nodes.case_bottom.geometry}
				material={materials.aluminum}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.s1.geometry}
				material={materials.stainless}
				position={[-84, 19.819, scaleSlider(s[0])]}
				rotation={[Math.PI / 2, 0, 0]}
			>
				<mesh
					geometry={nodes.sc1.geometry}
					material={materials.slider}
					position={[0, 0, -3.931]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={[1.4, 4.5, 2.3]}
				/>
			</mesh>
			<mesh
				geometry={nodes.s2.geometry}
				material={materials.stainless}
				position={[-60, 19.819, scaleSlider(s[1])]}
				rotation={[Math.PI / 2, 0, 0]}
			>
				<mesh
					geometry={nodes.sc2.geometry}
					material={materials.slider}
					position={[0, 0, -3.931]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={[1.4, 4.5, 2.3]}
				/>
			</mesh>
			<mesh
				geometry={nodes.s3.geometry}
				material={materials.stainless}
				position={[-36, 19.819, scaleSlider(s[2])]}
				rotation={[Math.PI / 2, 0, 0]}
			>
				<mesh
					geometry={nodes.sc3.geometry}
					material={materials.slider}
					position={[0, 0, -3.931]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={[1.4, 4.5, 2.3]}
				/>
			</mesh>
			<mesh
				geometry={nodes.s4.geometry}
				material={materials.stainless}
				position={[-12, 19.819, scaleSlider(s[3])]}
				rotation={[Math.PI / 2, 0, 0]}
			>
				<mesh
					geometry={nodes.sc4.geometry}
					material={materials.slider}
					position={[0, 0, -3.931]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={[1.4, 4.5, 2.3]}
				/>
			</mesh>
			<mesh
				geometry={nodes.s5.geometry}
				material={materials.stainless}
				position={[12, 19.819, scaleSlider(s[4])]}
				rotation={[Math.PI / 2, 0, 0]}
			>
				<mesh
					geometry={nodes.sc5.geometry}
					material={materials.slider}
					position={[0, 0, -3.931]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={[1.4, 4.5, 2.3]}
				/>
			</mesh>
			<mesh
				geometry={nodes.s6.geometry}
				material={materials.stainless}
				position={[36, 19.819, scaleSlider(s[5])]}
				rotation={[Math.PI / 2, 0, 0]}
			>
				<mesh
					geometry={nodes.sc6.geometry}
					material={materials.slider}
					position={[0, 0, -3.931]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={[1.4, 4.5, 2.3]}
				/>
			</mesh>
			<mesh
				geometry={nodes.s7.geometry}
				material={materials.stainless}
				position={[60, 19.819, scaleSlider(s[6])]}
				rotation={[Math.PI / 2, 0, 0]}
			>
				<mesh
					geometry={nodes.sc7.geometry}
					material={materials.slider}
					position={[0, 0, -3.931]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={[1.4, 4.5, 2.3]}
				/>
			</mesh>
			<mesh
				geometry={nodes.s8.geometry}
				material={materials.stainless}
				position={[84, 19.819, scaleSlider(s[7])]}
				rotation={[Math.PI / 2, 0, 0]}
			>
				<mesh
					geometry={nodes.sc8.geometry}
					material={materials.slider}
					position={[0, 0, -3.931]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={[1.4, 4.5, 2.3]}
				/>
			</mesh>
			<mesh
				geometry={nodes.case_top.geometry}
				material={materials.aluminum}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.foot1.geometry}
				material={materials.rubber}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.foot2.geometry}
				material={materials.rubber}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.foot3.geometry}
				material={materials.rubber}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.foot4.geometry}
				material={materials.rubber}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<group
				position={[-84, 21.5, -40]}
				rotation={[Math.PI / 2, 0, scaleKnob(k[0])]}
			>
				<mesh
					geometry={nodes.Trennlinie1002.geometry}
					material={materials.diffuse_253_253_227_255}
				/>
				<mesh
					geometry={nodes.Trennlinie1002_1.geometry}
					material={materials['Material.001']}
				/>
			</group>
			<group
				position={[-60, 21.5, -40]}
				rotation={[Math.PI / 2, 0, scaleKnob(k[1])]}
			>
				<mesh
					geometry={nodes.Trennlinie1009.geometry}
					material={materials.diffuse_253_253_227_255}
				/>
				<mesh
					geometry={nodes.Trennlinie1009_1.geometry}
					material={materials['Material.001']}
				/>
			</group>
			<group
				position={[-36, 21.5, -40]}
				rotation={[Math.PI / 2, 0, scaleKnob(k[2])]}
			>
				<mesh
					geometry={nodes.Trennlinie1010.geometry}
					material={materials.diffuse_253_253_227_255}
				/>
				<mesh
					geometry={nodes.Trennlinie1010_1.geometry}
					material={materials['Material.001']}
				/>
			</group>
			<group
				position={[-12, 21.5, -40]}
				rotation={[Math.PI / 2, 0, scaleKnob(k[3])]}
			>
				<mesh
					geometry={nodes.Trennlinie1011.geometry}
					material={materials.diffuse_253_253_227_255}
				/>
				<mesh
					geometry={nodes.Trennlinie1011_1.geometry}
					material={materials['Material.001']}
				/>
			</group>
			<group
				position={[12, 21.5, -40]}
				rotation={[Math.PI / 2, 0, scaleKnob(k[4])]}
			>
				<mesh
					geometry={nodes.Trennlinie1012.geometry}
					material={materials.diffuse_253_253_227_255}
				/>
				<mesh
					geometry={nodes.Trennlinie1012_1.geometry}
					material={materials['Material.001']}
				/>
			</group>
			<group
				position={[36, 21.5, -40]}
				rotation={[Math.PI / 2, 0, scaleKnob(k[5])]}
			>
				<mesh
					geometry={nodes.Trennlinie1013.geometry}
					material={materials.diffuse_253_253_227_255}
				/>
				<mesh
					geometry={nodes.Trennlinie1013_1.geometry}
					material={materials['Material.001']}
				/>
			</group>
			<group
				position={[60, 21.5, -40]}
				rotation={[Math.PI / 2, 0, scaleKnob(k[6])]}
			>
				<mesh
					geometry={nodes.Trennlinie1014.geometry}
					material={materials.diffuse_253_253_227_255}
				/>
				<mesh
					geometry={nodes.Trennlinie1014_1.geometry}
					material={materials['Material.001']}
				/>
			</group>
			<group
				position={[84, 21.5, -40]}
				rotation={[Math.PI / 2, 0, scaleKnob(k[7])]}
			>
				<mesh
					geometry={nodes.Trennlinie1015.geometry}
					material={materials.diffuse_253_253_227_255}
				/>
				<mesh
					geometry={nodes.Trennlinie1015_1.geometry}
					material={materials['Material.001']}
				/>
			</group>
			<mesh
				geometry={nodes.inside.geometry}
				material={materials.darkness}
				position={[0, 9.853, -2.316]}
				scale={[92.852, 6.077, 50.692]}
			/>
			<mesh
				geometry={nodes.screw1.geometry}
				material={materials.stainless}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.screw10.geometry}
				material={materials.stainless}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.screw11.geometry}
				material={materials.stainless}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.screw12.geometry}
				material={materials.stainless}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.screw2.geometry}
				material={materials.stainless}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.screw3.geometry}
				material={materials.stainless}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.screw4.geometry}
				material={materials.stainless}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.screw5.geometry}
				material={materials.stainless}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.screw6.geometry}
				material={materials.stainless}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.screw7.geometry}
				material={materials.stainless}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.screw8.geometry}
				material={materials.stainless}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.screw9.geometry}
				material={materials.stainless}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.nn1.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, 0.801]}
			/>
			<mesh
				geometry={nodes.nn1001.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, -2.622]}
			/>
			<mesh
				geometry={nodes.nn1002.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, 1.311]}
			/>
			<mesh
				geometry={nodes.nn1003.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, 3.065]}
			/>
			<mesh
				geometry={nodes.nn1004.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, 0.454]}
			/>
			<mesh
				geometry={nodes.nn1005.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, -2.3]}
			/>
			<mesh
				geometry={nodes.nn1006.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, -2.903]}
			/>
			<mesh
				geometry={nodes.nn1007.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, -0.092]}
			/>
			<mesh
				geometry={nodes.ww1.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.ww1001.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.ww1002.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.ww1003.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.ww1004.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.ww1005.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.ww1006.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				geometry={nodes.ww1007.geometry}
				material={materials.hardware}
				rotation={[Math.PI / 2, 0, 0]}
			/>
		</group>
	)
}

useGLTF.preload('/midi.glb')
